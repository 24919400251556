<template>
  <div>
    <a :class="`save-button ${savedIndex >= 0 ? 'active' : ''}`" href="javascript:void(0)"   @click="saveData(zar_id)">
      <Tooltip placement="left" content="Хадгалсан"  v-if="savedIndex >= 0">
        <i class="ivu-icon ivu-icon-md-bookmark" ></i>
      </Tooltip>
      <Tooltip placement="left" content="Хадгалах" v-else>
        <i class="ivu-icon ivu-icon-md-bookmark" ></i>
      </Tooltip>
    </a>
  </div>
</template>

<script>
import {
  POST_DELETE_BAIGUULAGA_AJIL_ZAR,
  POST_SAVE_BAIGUULAGA_AJIL_ZAR,
} from "../../graphql/queries";
import {mapGetters} from "vuex";
export default {
  name: "save_button_ajil_zar",
  props: ["zar_id", "type"],
  data () {
    return {
      isSaved: false,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'b_a_zar',
    ]),
    savedIndex(){
      if(this.b_a_zar){
        let savedIndex = this.b_a_zar.findIndex(item=>item.zar_id == this.zar_id);
        return savedIndex;
      } else {
        return -1;
      }
    },
  },
  mounted() {


  },
  methods: {
    deleteBaiguullagaData(savedItem){
      this.$apollo.mutate({mutation: POST_DELETE_BAIGUULAGA_AJIL_ZAR,
        variables: {
          id: savedItem.id,
        }
      }).then(() =>{
        this.$store.commit("removeBaiguullagaAjilZar",  savedItem.zar_id);
      });
    },
    saveData(zar_id) {
      if(this.savedIndex >= 0){
        this.deleteBaiguullagaData(this.b_a_zar[this.savedIndex])
      } else {
        this.$apollo.mutate({
          mutation:  POST_SAVE_BAIGUULAGA_AJIL_ZAR,
          variables: {
            user_id: this.user.id, zar_id: zar_id,
          }
        }).then(({data}) => {
          this.$store.commit("addToBaiguullagaAjilZar",  data.createSaveZarBaiguullagaAjilZar);
          this.$Notice.success({
            title: this.$t("Successfully_saved"),
            desc: this.$t("Your_saved_ads_Saved_Works_ads_menu")
          });
        })
      }
    },
    // saveData(zar_id) {
    //   this.user_id = this.user.id
    //   this.zar_id = zar_id
    //   //console.log(this.user_id);
    //   //console.log(zar_id);
    //   this.$apollo.mutate({
    //     mutation: this.type == "baiguullaga" ? POST_SAVE_BAIGUULAGA_AJIL_ZAR : POST_SAVE_BRIGAD_AJIL_ZAR,
    //     variables: this.type == "baiguullaga" ?  {
    //       user_id: this.user_id, zar_id: this.zar_id,
    //     } : {
    //       user_id: this.user_id, zar_id: this.zar_id,
    //     },
    //   }).then(() => {
    //     this.isSaved = true;
    //     this.$Notice.success({
    //       title: 'Амжилттай хадгалагдлаа',
    //     });
    //   })
    // },
  },
}
</script>
